@font-face {
  font-family: Noto Sans Lao;
  src: url(./assets/NotoSansLao-Regular.ttf)
  ;
}

body {
    font-family: Noto Sans Lao;
    margin: 0;
    padding: 0;
  }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 

table {
  border-collapse: collapse;
}
th,td {
  border: 1px solid #a2a2a2;
}