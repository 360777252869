@font-face {
  font-family: Noto Sans Lao;
  src: url(../assets/NotoSansLao-Regular.ttf)
  ;
}

body {
    font-family: Noto Sans Lao;
    margin: 0;
    padding: 0;
    background-color: #f0f0f0; /* Set background color */

  }
  .search-input{
    border: 1px solid gray;
    border-radius: 10px;
    padding: 7px 10px;font-size: 16px;
    font-family: Noto Sans Lao;
    margin-left: 0.1rem;
  }
  .date-input{
    border: 1px solid gray;
    border-radius: 10px;
    padding: 7px 10px;font-size: 16px;
  }
.container {
    width: 100%; /* Adjust as needed */
    max-width: 1200px; /* Adjust as needed */
    margin: 0 auto; /* Center the container horizontally */
    padding: 20px; /* Add padding inside the container */
    box-sizing: border-box; /* Include padding and border in the container's total width */
    border: 1px solid #ccc; /* Add a border */
    border-radius: 5px; /* Add rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  }
/* Style for the table */
table {
    width: 100%;
    border-collapse: collapse;
  }
  
  table, th, td {
    border: 1px solid rgb(210, 210, 210);
  }
  
  th, td {
    padding: 8px;
    text-align: left;
  }
  
  /* Style for the form inputs */
  input[type="text"],
  input[type="password"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    font-family: Noto Sans Lao;
  }
  
  /* Style for the buttons */
  button {
    padding: 8px 16px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    transition: 0.3s;
    font-family: Noto Sans Lao;

  }
  
  button:hover {
    background-color: #0056b3;
  }
  .reset-button {
    background-color: red;
    margin-left: 16.1rem;
  }
  
  .reset-button:hover {
    background-color: rgb(88, 88, 88);
  }
  
  /* Style for the form labels */
  label {
    margin-bottom: 8px;
    display: block;
  }
  
  /* Style for the table row with editing controls */
  tr.editing-controls td {
    padding-top: 10px;
  }
  
  /* Style for the editing controls buttons */
  .editing-controls button {
    margin-right: 5px;
  }
  
  /* Style for the 'Buy' button */
  .buy-button {
    background-color: #28a745;
  }
  
  .buy-button:hover {
    background-color: #218838;
  }
  
  /* Style for the 'Edit' button */
  .edit-button {
    background-color: rgb(51, 44, 44);
  }
  
  .edit-button:hover {
    background-color: black;
  }
  
  /* Style for the 'Delete' button */
  .delete-button {
    background-color: #dc3545;
  }
  
  .delete-button:hover {
    background-color: #c82333;
  }
  

  /* style.css */

.login-container {
  width: 350px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.login-container h2 {
  text-align: center;
}

.input-group {
  margin-bottom: 15px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
}

.input-group input {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.button-login {
  width: 100%;
  padding: 10px;
  background-color: #ff9900;
  border: none;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.button-login:hover {
  background-color: #b33600;
}
.image-container{
 height: 50px;
 width: 50px;
}